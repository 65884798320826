import React from 'react'
import NotFound from '../Components/NotFound'

export default function NotFoundPage() {
  return (
    <>
      <NotFound />
    </>
  )
}
