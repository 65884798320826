import React from 'react'
import ProfileData from '../Components/ProfileData'
import './ProfilePage.css'

export default function ProfilePage() {
  return (
    <>
        <ProfileData />
    </>
  )
}
