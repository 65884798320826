import React from 'react'
import LoginSection from '../Components/LoginSection'
import './LoginPage.css'

function LoginPage() {
  return (
    <>
      <LoginSection/>
    </>
  )
}

export default LoginPage