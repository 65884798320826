import React from 'react'
import './NotFound.css'

function NotFound() {
  return (
    <div className='container'>
      <div className="notfound">
        <h3 className='notfound-text'>Ничего не найдено</h3>
      </div>
    </div>
  )
}

export default NotFound