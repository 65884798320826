import React from 'react'
import RegistrationSecion from '../Components/RegistrationSection'
import './RegistrationPage.css'

function RegistrationPage() {
  return (
    <>
      <RegistrationSecion/>
    </>
  )
}

export default RegistrationPage