import React from 'react'
import { Button, List } from 'antd'
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import './CargoDetails.css';
import { useAuth } from '../contexts/AuthContextProvider';

function CargoDetails(props) {

    const { deleteTrack } = useAuth();

    const data = [
        `Китай`,
        `Кыргызстан`,
        `Получено`
    ];

    async function handleDeleteTrack(code) {
        let res = await deleteTrack(code);
        props.trackUpdated(!props.updated);
    }

  return (
    <>
        <div className="details-data">
            <h3 className='details-data-title'>Информация о грузе</h3>
            <List
                header={<div>Код: {props.track.track_code}</div>}
                bordered
                dataSource={data}
                renderItem={(item) => {
                    if(item == "Китай") {
                        return <List.Item>{props.track.warehouse_china ? <CheckCircleTwoTone twoToneColor="#5FCF73"/> : <MinusCircleTwoTone twoToneColor="#5E5E5E"/>} Склад в Китае {props?.track?.date_arrival_china ? `(${props?.track?.date_arrival_china})` : ""}</List.Item>                    } else if (item == "Кыргызстан") {
                        return <List.Item>{props.track.warehouse_kg ? <CheckCircleTwoTone twoToneColor="#5FCF73"/> : <MinusCircleTwoTone twoToneColor="#5E5E5E"/>} Пункт получения {props?.track?.date_arrival_kg ? `(${props?.track?.date_arrival_kg})` : ""}</List.Item>
                    } else if (item == "Получено") {
                        return <List.Item>{props.track.received_by_client ? <CheckCircleTwoTone twoToneColor="#5FCF73"/> : <MinusCircleTwoTone twoToneColor="#5E5E5E"/>} Получено {props?.track?.date_received_by_client ? `(${props?.track?.date_received_by_client})` : ""}</List.Item>
                    } else {
                        return <List.Item>{item}</List.Item>
                    }
                }}
            />
            <Button onClick={() => handleDeleteTrack(props.track.track_code)} style={{margin: "10px 0"}} danger type="primary">Удалить</Button>
        </div>
    </>
  )
}

export default CargoDetails